import { createRouter, createWebHistory } from 'vue-router'

const OrdersView = () => import('@/views/OrdersView.vue')
const NewOrderView = () => import('@/views/NewOrderView.vue')
const AddressBookView = () => import('@/views/AddressBookView.vue')
const TicketView = () => import('@/views/TicketView.vue')
const CalendarView = () => import('@/views/CalendarView.vue')
const ScheduleView = () => import('@/views/ScheduleView.vue')
const ScheduleTicket = () => import('@/components/ScheduleTicket.vue')
const ScheduleStats = () => import('@/components/ScheduleStats.vue')
const PlannerView = () => import('@/views/PlannerView.vue')
const PlannerTasks = () => import('@/components/planner/PlannerTasks.vue')
const PlannerGroups = () => import('@/components/planner/PlannerGroups.vue')
const PlannerGroup = () => import('@/components/planner/PlannerGroup.vue')
const PlannerCapacity = () => import('@/components/planner/PlannerCapacity.vue')
const PlannerProcesses = () => import('@/components/planner/PlannerProcesses.vue')
const PlannerProcess = () => import('@/components/planner/PlannerProcess.vue')
const PlannerGroupCreate = () => import('@/components/planner/PlannerGroupCreate.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/v2/',
      redirect: '/v2/tickets',
    },
    {
      path: '/v2/orders',
      name: 'orders-list',
      component: OrdersView,
    },
    {
      path: '/v2/new-order',
      name: 'new-order',
      component: NewOrderView,
    },
    {
      path: '/v2/tickets',
      name: 'tickets-list',
      component: TicketView,
    },
    {
      path: '/v2/tickets/:id',
      name: 'ticket-detail',
      component: TicketView,
    },
    {
      path: '/v2/tickets/:id/images/:image',
      name: 'ticket-view-images',
      component: TicketView,
    },
    {
      path: '/v2/calendar',
      name: 'calendar',
      component: CalendarView,
    },
    {
      path: '/v2/address-book',
      name: 'address-book',
      component: AddressBookView,
    },
    {
      path: '/v2/planner',
      name: 'planner',
      component: PlannerView,
      children: [
        {
          path: 'tasks',
          name: 'planner-tasks',
          component: PlannerTasks,
        },
        {
          path: 'groups',
          name: 'planner-groups',
          component: PlannerGroups,
        },
        {
          path: 'groups/comments/:id',
          name: 'planner-groups-comments',
          component: PlannerGroups,
        },
        {
          path: 'groups/:id',
          name: 'planner-group-details',
          component: PlannerGroup,
        },
        {
          path: 'process',
          name: 'planner-processes',
          component: PlannerProcesses,
        },
        {
          path: 'process/:id',
          name: 'planner-process-details',
          component: PlannerProcess,
        },
        {
          path: 'capacity',
          name: 'planner-capacity',
          component: PlannerCapacity,
        },
        {
          path: 'create-group',
          name: 'planner-group-create',
          component: PlannerGroupCreate,
        },
      ]
    },
    {
      path: '/v2/schedule/:year?/:month?',
      name: 'schedule',
      component: ScheduleView,
      children: [
        {
          path: 'ticket/:id',
          name: 'schedule-ticket',
          component: ScheduleTicket,
        },
        {
          path: 'stats/:week?',
          name: 'schedule-stats',
          component: ScheduleStats,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (
      (to.name === 'ticket-detail' && from.name !== 'ticket-view-images') ||
      to.name === 'schedule-ticket'
    ) {
      return { top: 0 }
    }
    return savedPosition
  },
})

export default router
